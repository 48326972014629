<template>
    <div class="form_container">
        <form @submit.prevent="submitForm" method="post" novalidate enctype="multipart/form-data">
              <h3>HABLA CON NUESTRO EQUIPO DE VENTAS</h3>
                <label for="nombre">NOMBRE (REQUERIDO):</label>
                <input v-model="formData.nombre" type="text" id="nombre" name="nombre">
                <div v-if="errors.nombre && errors.nombre.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.nombre">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="apellido">APELLIDO (REQUERIDO):</label>
                <input v-model="formData.apellido" type="text" id="apellido" name="apellido"> 
                <div v-if="errors.apellido && errors.apellido.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.apellido">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="organizacion">ORGANIZACION:</label>
                <input v-model="formData.organizacion" type="text" id="organizacion" name="organizacion">
                <div v-if="errors.organizacion && errors.organizacion.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.organizacion">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="telefono">TELEFONO (REQUERIDO):</label>
                <input v-model="formData.telefono" type="tel" id="telefono" name="telefono">
                <div v-if="errors.telefono && errors.telefono.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.telefono">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="email">EMAIL (REQUERIDO):</label>
                <input v-model="formData.email" type="email" id="email" name="email">
                <div v-if="errors.email && errors.email.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.email">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="pais">PAIS (REQUERIDO):</label>
                <input v-model="formData.pais" type="text" id="pais" name="pais">
                <div v-if="errors.pais && errors.pais.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.pais">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="mensaje">TU MENSAJE:</label>
                <textarea class="mensaje" v-model="formData.mensaje" type="text" id="mensaje" name="mensaje"></textarea>
                <div v-if="errors.mensaje && errors.mensaje.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.mensaje">{{ error }}</li>
                  </ul>
                </div>
                
                <button class="submit" type="submit">ENVIAR</button>
                <span id="mensajeJS"></span>
        </form>
    </div>
</template>


<script>
import axios from 'axios';
export default{
  data(){
    return{
      formData: {},
      errors: {},
    };
  },
  methods: {
    resetForm() {
      this.formData = {
        nombre: '',
        apellido: '',
        organizacion: '',
        telefono: '',
        email: '',
        pais: '',
        mensaje: ''
      };
    },
    submitForm (){
      this.errors = {};
      grecaptcha.ready(() => {
        grecaptcha.execute('6Lfgx6ApAAAAALjaik-3RpTbo4eyKCF82nuu3Xgg', { action: 'submit' })
        .then(token => {
          let mensaje = document.getElementById('mensajeJS');
          mensaje.textContent = 'Enviando formulario...';
          mensaje.className = 'load';
          const formData = new FormData(); 
          formData.append('g-recaptcha-response', token);
          for (let key in this.formData) {
              formData.append(key, this.formData[key]);
          }
          axios.post('http://127.0.0.1/api/formulario-kcd-software', formData, {
              headers: {'Content-Type': 'multipart/form-data',},
          })
          .then(response => {
              console.log(response);
              this.errors = {};
              let mensaje = document.getElementById('mensajeJS');
              mensaje.textContent = 'Correo enviado.';
              mensaje.className = 'enviado';
              this.resetForm();
          }) 
          .catch(error => {
            if (error.response) {
              if (error.response.status === 422) {
                // Errores de validación
                let mensaje = document.getElementById('mensajeJS');
                mensaje.textContent = 'Ocurrió un error al enviar el formulario. Revise los campos.';
                mensaje.className = 'error';
                // Asigna los mensajes de error a cada campo correspondiente
                this.errors = error.response.data.error;
              }else if(error.response.status === 403){
                mensaje.textContent = 'Fallo el recaptcha. Vuelva a intentarlo.';
                mensaje.className = 'error';
              }
            }
          });
        })
      });
    }
  },
}
</script>


<style lang="scss" scoped>
.form_container{
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.form_container form {
  width: 100%; 
  display: flex;
  flex-direction: column;
}

h3{
  text-align: center;
  color: #243757;
  font-size: 1.5rem;
  padding-bottom: 0;
  margin-bottom: 40px;
}

label{
    margin: 10px;
    margin-left: 0;
    margin-right: 0;
    font-size: 17px;
    font-weight: bold;
    color: rgb(70, 69, 69);
}
input{
    height: 3vh;
}

.archivo{
    margin-top: 40px;
}
.mensaje{
    height: 10vh;
}

.submit{
    cursor: pointer;
    align-self: center;
    margin: 20px;
    font-size: 1.3rem;
    font-weight: bold;
    background-color: #E19F41;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
}

.submit:hover{
    background-color: #915f1a;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.enviado{
  font-weight: bold;
  color: green;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

.error{
  font-weight: bold;
  color: red;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

.load{
  font-weight: bold;
  color: black;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

@media (max-width: 1280px){
  div.form_container {
    width: 40%;
  }

  input.submit{
    width: 20%;
    height: 7vh;
  }
  
}

@media (max-width: 1199px){
  .form_container{
    width: 35%;
  }

  form h3{
    font-size: 20px;
  }
}


@media (max-width: 800px){
  .form_container{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    justify-items: center; 
    align-items: center;
  }

  .form_container {
    width: 40%;
  }

  form h3{
    width: 100%;
  }
}


@media (max-width: 600px){
  .form_container{
    width: 50%;
  }
}

@media (max-width: 500px){
  label{
    font-size: 13px;
  }
}
</style>