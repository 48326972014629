<template>
    <div class="form_container">
        <form @submit.prevent="formularioVentaCanino" method="post" enctype="multipart/form-data">
            <a href="declaracion-jurada.pdf" download style="font-size: 15px; color: dodgerblue;" >CONSULTE NUESTRA POLÍTICA DE PRIVACIDAD</a> <br> <br>
              <a href="terminos-condiciones.pdf" download style="font-size: 15px; color: dodgerblue;">COSULTE AVISO LEGAL - TERMINOS DE USO</a>
              <br><br>
            <h3>SR/A: Presidente del Kennel Club Digital <br><br>
            Tengo el agrado de dirigirme a Ud., a los efectos de COMUNICARLE LA DENUNCIA DE VENTA DEL
            CANINO DE PURA RAZA del CRIADERO</h3>
            <label for="fecha">FECHA DE HOY (REQUERIDO):</label>
            <input v-model="formData.fecha" type="date" id="fecha" name="fecha">
            <div v-if="errors.fecha" class="error-message">
                <ul>
                    <li v-for="error in errors.fecha">{{ error }}</li>
                </ul>
            </div>
            <br><br>
                <label for="nombre-ejemplar">NOMBRE DEL EJEMPLAR (REQUERIDO):</label>
                <input v-model="formData.nombre_ejemplar" type="text" id="nombre-ejemplar" name="nombre-ejemplar"> 
                <div v-if="errors.nombre_ejemplar" class="error-message">
                    <ul>
                        <li v-for="error in errors.nombre_ejemplar">{{ error }}</li>
                    </ul>
                </div>
                <label for="national-number">NÚMERO NACIONAL DEL KCA (REQUERIDO):</label>
                <input v-model="formData.national_number" type="text" id="national-number" name="national-number">
                <div v-if="errors.national_number && errors.national_number.length > 0" class="error-message">
                    <ul>
                    <li v-for="error in errors.national_number">{{ error }}</li>
                    </ul>
                </div>
                <label for="race-number">NÚMERO DE RAZA (REQUERIDO):</label>
                <input v-model="formData.race_number" type="text" id="race-number" name="race-number">
                <div v-if="errors.race_number && errors.race_number.length > 0" class="error-message">
                    <ul>
                    <li v-for="error in errors.race_number">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="microchip">NÚMERO DE MICROCHIP:</label>
                <input v-model="formData.microchip" type="text" id="microchip" name="microchip" >
                    <br>
                <label for="raza">RAZA (REQUERIDO):</label>
                <input v-model="formData.raza" type="text" id="raza" name="raza"> 
                <div v-if="errors.raza" class="error-message">
                    <ul>
                        <li v-for="error in errors.raza">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="variedad-raza">VARIEDAD DE RAZA - <i>si no hubiere una variedad, completar con "Sin Variedad" </i>- (REQUERIDO):</label>
                <input v-model="formData.variedad_raza" type="text" id="variedad-raza" name="variedad-raza"> 
                <div v-if="errors.variedad_raza" class="error-message">
                    <ul>
                        <li v-for="error in errors.variedad_raza">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="color">COLOR (REQUERIDO):</label>
                <input v-model="formData.color" type="text" id="color" name="color">
                <div v-if="errors.color" class="error-message">
                    <ul>
                        <li v-for="error in errors.color">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="opciones">SEXO (REQUERIDO)</label>
                <select v-model="formData.sexo" id="sexo" name="opciones">
                    <option value="opcion1" disabled>--Por favor, elige una opción--</option>
                    <option value="Macho">Macho</option>
                    <option value="Hembra">Hembra</option>
                </select>
                <div v-if="errors.sexo" class="error-message">
                    <ul>
                        <li v-for="error in errors.sexo">{{ error }}</li>
                    </ul>
                </div>
                <br>
                <label for="criador">CRIADOR (REQUERIDO):</label>
                <input v-model="formData.criador" type="text" id="criador" name="criador">
                <div v-if="errors.criador" class="error-message">
                    <ul>
                        <li v-for="error in errors.criador">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="email_criador">EMAIL (REQUERIDO):</label>
                <input v-model="formData.email_criador" type="email" id="email_criador" name="email_criador" @input="validarEmailCriador">
                <div v-if="errors.email_criador" class="error-message">
                    <ul>
                        <li v-for="error in errors.email_criador">{{ error }}</li>
                    </ul>
                </div>
                <label for="email_criador_confirmation">CONFIRMAR EMAIL (REQUERIDO):</label>
                <input v-model="formData.email_criador_confirmation" type="email" id="email_criador_confirmation" name="email_criador_confirmation" @input="validarEmailCriadorConfirmation">
                <div v-if="errors.email_criador_confirmation && errors.email_criador_confirmation.length > 0" class="error-message">
                    <ul>
                    <li v-for="error in errors.email_criador_confirmation">{{ error }}</li>
                    </ul>
                </div>
                <br>

                <label class="archivo" for="archivo">Adjuntar FRENTE del Documento de Identidad del Criador (REQUERIDO):</label>
                <input ref="fileInput" type="file" id="frente-documento-criador" name="archivo" multiple @change="handleFileChange($event, 0)" required>
                <div v-if="errors[`archivos.1.0`] || errors[`archivos.1.1`]"  class="error-message">
                    <ul>
                    <li v-for="error in errors[`archivos.1.0`]">{{ error }}</li>
                  </ul>
                  <ul>
                    <li v-for="error in errors[`archivos.1.1`]">{{ error }}</li>
                  </ul>
                </div>
                <br>

            <h2>VENDIDO AL SR/A</h2>
                <label for="nombre">NOMBRE DEL PROPIETARIO (REQUERIDO):</label>
                <input v-model="formData.nombre" type="text" id="nombre" name="nombre">
                <div v-if="errors.nombre" class="error-message">
                    <ul>
                        <li v-for="error in errors.nombre">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="apellido">APELLIDO DEL PROPIETARIO (REQUERIDO):</label>
                <input v-model="formData.apellido" type="text" id="apellido" name="apellido"> 
                <div v-if="errors.apellido" class="error-message">
                    <ul>
                        <li v-for="error in errors.apellido">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                    <label for="documento">NÚMERO DE DOCUMENTO (REQUERIDO):</label>
                <input v-model="formData.documento" type="text" id="documento" name="documento">
                <div v-if="errors.documento" class="error-message">
                    <ul>
                        <li v-for="error in errors.documento">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="celular">TELÉFONO CELULAR (REQUERIDO):</label>
                <input v-model="formData.celular" type="tel" id="celular" name="celular">
                <div v-if="errors.celular" class="error-message">
                    <ul>
                        <li v-for="error in errors.celular">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="email_venta">EMAIL (REQUERIDO):</label>
                <input v-model="formData.email_venta" type="email" id="email_venta" name="email_venta" @input="validarEmailVenta">
                <div v-if="errors.email_venta" class="error-message">
                    <ul>
                        <li v-for="error in errors.email_venta">{{ error }}</li>
                    </ul>
                </div>
                <label for="email_venta_confirmation">CONFIRMAR EMAIL (REQUERIDO):</label>
                <input v-model="formData.email_venta_confirmation" type="email" id="email_venta_confirmation" name="email_venta_confirmation" @input="validaremail_ventaConfirmation">
                <div v-if="errors.email_venta_confirmation && errors.email_venta_confirmation.length > 0" class="error-message">
                    <ul>
                    <li v-for="error in errors.email_venta_confirmation">{{ error }}</li>
                    </ul>
                </div>
                <br>
                <label for="domicilio">DOMICILIO (REQUERIDO):</label>
                <input v-model="formData.domicilio" type="text" id="domicilio" name="domicilio"> 
                <div v-if="errors.domicilio" class="error-message">
                    <ul>
                        <li v-for="error in errors.domicilio">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="codigo-postal">CODIGO POSTAL (REQUERIDO):</label>
                <input v-model="formData.codigo_postal" type="text" id="codigo-postal" name="codigo-postal"> 
                <div v-if="errors.codigo_postal" class="error-message">
                    <ul>
                        <li v-for="error in errors.codigo_postal">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="ciudad">CIUDAD (REQUERIDO):</label>
                <input v-model="formData.ciudad" type="text" id="ciudad" name="ciudad">
                <div v-if="errors.ciudad" class="error-message">
                    <ul>
                        <li v-for="error in errors.ciudad">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="provincia">PROVINCIA (REQUERIDO):</label>
                <input v-model="formData.provincia" type="text" id="provincia" name="provincia">
                <div v-if="errors.provincia" class="error-message">
                    <ul>
                        <li v-for="error in errors.provincia">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="pais">PAÍS (REQUERIDO):</label>
                <input v-model="formData.pais" type="text" id="pais" name="pais">
                <div v-if="errors.pais" class="error-message">
                    <ul>
                        <li v-for="error in errors.pais">{{ error }}</li>
                    </ul>
                </div>
                <label for="terminos-y-condiciones" id="terminos-y-condiciones" style="font-size: 15px; display: flex; align-items: center;" >
                  <input v-model="formData.terminos_condiciones"  type="checkbox" id="terminos-y-condiciones" style="margin-right: 5px;">  
                  He leído y acepto los Términos de Uso y Política de Privacidad (REQUERIDO)
                </label>
                <div v-if="errors.terminos_condiciones" class="error-message">
                  <ul style="margin-top: 0;" >
                    <li v-for="error in errors.terminos_condiciones">{{ error }}</li>
                  </ul>
                </div>
                <label for="declaracion-jurada" id="declaracion-jurada" style="font-size: 15px; display: flex;" >
                  <input  v-model="formData.declaracion_jurada" type="checkbox" id="declaracion-jurada" style="margin-right: 5px;">  
                  Declaro que los datos proporcionados son reales y están bajo condición de Declaración Jurada (REQUERIDO)
                </label>
                <div v-if="errors.declaracion_jurada" class="error-message">
                  <ul style="margin-top: 0;" >
                    <li v-for="error in errors.declaracion_jurada">{{ error }}</li>
                  </ul>
                </div>
                
                <button class="submit" type="submit">ENVIAR</button>
                <span id="mensajeJS"></span>
        </form>

        
    </div>
</template>


<script>
import axios from 'axios';
export default{
  data(){
    return{ 
        archivos: [[]],
        formData: {},
        errors: {},
    };
  },

  methods: {

    handleFileChange(event, index) {
      const selectedFiles = Array.from(event.target.files);

      if (selectedFiles.length > 2) {
        // Validar que no se adjunten más de dos archivos por campo
        this.errors[`archivo${index + 1}`] = ['Por favor, adjunta hasta dos archivos.'];
        this.archivos[index] = []; // Reiniciar archivos para este campo
      } else {
        // Guardar los archivos en el índice correspondiente
        this.archivos[index] = selectedFiles;
        this.errors[`archivo${index + 1}`] = []; // Limpiar errores
      }
    },

    resetForm() {
        this.formData = {
            fecha: '',
            nombre_ejemplar: '',
            microchip: '',
            raza: '',
            variedad_raza: '',
            color: '',
            sexo: '',
            criador: '',
            email_criador: '',
            archivo: null,
            nombre: '',
            apellido: '',
            documento: '',
            celular: '',
            email_venta: '',
            domicilio: '',
            codigo_postal: '',
            ciudad: '',
            provincia: '',
            pais: '',
            terminos_condiciones: false,
            declaracion_jurada: false
        };
        this.$refs.fileInput.value = '';
        this.archivos = [[]];
    },

    formularioVentaCanino (){
        this.errors = {};
        grecaptcha.ready(() => {
            grecaptcha.execute('6Lfgx6ApAAAAALjaik-3RpTbo4eyKCF82nuu3Xgg', { action: 'submitVentaCanino' })
            .then(token => {
                let mensaje = document.getElementById('mensajeJS');
                mensaje.textContent = 'Enviando formulario...';
                mensaje.className = 'load';
                const formData = new FormData(); 
                formData.append('g-recaptcha-response', token);
                for (let key in this.formData) {
                    formData.append(key, this.formData[key]);
                }
                this.archivos.forEach((campo, index) => {
                    campo.forEach((archivo, i) => {
                        formData.append(`archivos[${index+1}][]`, archivo); // Etiquetar archivos por campo e índice
                    });
                });
                axios.post('http://127.0.0.1/api/formulario-kcd-venta-canino', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    let mensaje = document.getElementById('mensajeJS');
                    mensaje.textContent = 'Correo enviado.';
                    mensaje.className = 'enviado';
                    this.resetForm();
                }) 
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 422) {
                            // Errores de validación
                            let mensaje = document.getElementById('mensajeJS');
                            mensaje.textContent = 'Ocurrió un error al enviar el formulario. Revise los campos.';
                            mensaje.className = 'error';
                            // Asigna los mensajes de error a cada campo correspondiente
                            this.errors = error.response.data.error;
                        }else if(error.response.status === 403){
                            mensaje.textContent = 'Fallo el recaptcha. Vuelva a intentarlo.';
                            mensaje.className = 'error';
                        }
                    }
                });
            })
        })
    },

  },
};
</script>


<style lang="scss" scoped>
.form_container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form_container form {
  width: 60%; 
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

label, a{
    margin: 10px;
    margin-left: 0;
    font-weight: bold;
    color: rgb(70, 69, 69);
}
input, select{
    height: 4vh;
}

.archivo{
    margin-top: 40px;
}

fieldset{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.submit{
    cursor: pointer;
    align-self: center;
    margin: 20px;
    font-size: 1.3rem;
    font-weight: bold;
    background-color: #E19F41;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
}

.submit:hover{
    background-color: #915f1a;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.enviado{
  font-weight: bold;
  color: green;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

.error{
  font-weight: bold;
  color: red;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

.load{
  font-weight: bold;
  color: black;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

@media (max-width: 1280px){
   h2{
    font-size: 1.3rem;
   }
   .submit{
    font-size: 15px;
  }
}
@media (max-width: 800px){
    .form_container{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    justify-items: center; 
    align-items: center;
  }

  .form_container form{
    width: 90%;
    padding-bottom: 0;
  }

  h2{
    font-size: 17px;
    text-wrap: balance;
  }

  h3 {
    font-size: 15px;
    text-wrap: balance;
  }

  label{
    font-size: 13px;
  }

  input{
    height: 3vh;
  }

  select{
    height: 3vh;
    font-size: 13px;
  }
  
  #terminos-y-condiciones, #declaracion-jurada{
    font-size: 11px !important;
    align-items: end !important;
  }
}
</style>