import { ref } from 'vue';

const titles = ref({});

export const titleChange = {
    setTitle(pageName, title) {
        titles.value[pageName] = title;
        updateTitle();
    },
    removeTitle(pageName) {
        delete titles.value[pageName];
        updateTitle();
    },
};

function updateTitle() {
    const currentPath = window.location.pathname;
    const title = titles.value[currentPath] || 'Página de inicio';
    document.title = title + ' | Kennel Club Digital';
}
